import '../../scss/shop/main.scss';

document.addEventListener('DOMContentLoaded', () => {
    const controlWrapper = document.querySelector('.count-control');
    if (!controlWrapper) return;
    const output = controlWrapper.querySelector('.count');
    const outputHidden = controlWrapper.querySelector('input.count');
    const plus = controlWrapper.querySelector('.plus');
    const minus = controlWrapper.querySelector('.minus');
    const price = +document.querySelector('.heading .h2 span')?.innerText.replaceAll(' ', '');
    const priceOutput = document.querySelector('.description-price-wrapper .price');
    console.log('priceOutput', priceOutput.dataset.currency)
    plus.addEventListener('click', () => {
        const hiddenOutput = document.querySelector('.description-price-wrapper .hidden-sum')
        outputHidden.value++;
        output.style.width = outputHidden.value.length * 13 + 'px';
        output.innerText = outputHidden.value;
        priceOutput.innerText = reverseString(space(price * outputHidden.value + '')) + ` ${priceOutput.dataset.currency}`;
        hiddenOutput.value = price * output.value * 100 + '';
    })
    minus.addEventListener('click', () => {
        const hiddenOutput = document.querySelector('.description-price-wrapper .hidden-sum')
        if (outputHidden.value > 1) {
            outputHidden.value--;
            output.style.width = outputHidden.value.length * 13 + 'px';
            output.innerText = outputHidden.value;
            priceOutput.innerText = reverseString(space(price * outputHidden.value + '')) + ` ${priceOutput.dataset.currency}`;
            hiddenOutput.value = price * output.value * 100 + '';
        }
    })
    const checkout = document.querySelector('.checkout-btn');
    if (checkout) {
        checkout.addEventListener('click', () => {
            document.location.href = "pay.php?cnt=" + output.innerText;
        })
    }

    const checkoutUsd = document.querySelector('.checkout-usd-btn');

    if (checkoutUsd) {
        checkoutUsd.addEventListener('click', () => {
            document.location.href = "pay_usd.php?cnt=" + output.innerText;
        })
    }

    const checkoutSmallSumRub = document.querySelector('.checkout-small-sum-btn');

    if (checkoutSmallSumRub) {
        checkoutSmallSumRub.addEventListener('click', () => {
            document.location.href = "pay_small_rub.php?cnt=" + output.innerText;
        })
    }
    
})

const reverseString = (str) => (str.split("").reverse().join(""));

const space = (string) => {
    // defaults to a space after 4 characters:
    const after = 3;

    /* removes all characters in the value that aren't a number,
       or in the range from A to Z (uppercase): */
    const v = reverseString(string),
      /* creating the regular expression, to allow for the 'after' variable
         to be used/changed: */
      reg = new RegExp(".{" + after + "}","g")
    return v.replace(reg, (a, b, c) => a + ' ');
}